import React, {MutableRefObject, useEffect} from "react";
import "src/styles/scrollPrompt.css";

type PromptProps = {
    scrollElement: MutableRefObject<any>,
}

export default function ScrollPrompt(props: PromptProps) {
    useEffect(() => {
        if (props.scrollElement.current) {
            props.scrollElement.current.addEventListener('scroll', checkRemovePrompt);
        }
    }, [props.scrollElement]);

    const checkRemovePrompt = () => {
        if (props.scrollElement.current.scrollTop > window.innerHeight / 2) {
            const prompt = document.getElementById("scrollPrompt");
            if (prompt) {
                prompt.style.opacity = "0";
                setTimeout(() => {
                    prompt.style.display = "none"
                }, 1000)
            }
        }
    }


    return (
        <div
            className="absolute left-1/2 bottom-12 text-slate-100 text-2xl opacity-50 w-fit h-fit -translate-x-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" id="scrollPrompt" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor"
                 className="w-14 h-14">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 8l-7.5 7.5-7.5-7.5"/>
            </svg>
        </div>
    )
}