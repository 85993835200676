import React, {useEffect} from "react";
import 'src/styles/background.css';


interface WrapperProps {
    children: React.ReactNode;
}


const Background: React.FC<WrapperProps> = ({children}) => {
    let mouseX = 0;
    let mouseY = 0;
    let radialGradient: NodeListOf<HTMLElement> | null = null;

    useEffect(() => {
        background();
        window.addEventListener('resize', background);
        setupGradientFollow();
        gradientFollow();
    }, [])

    const background = () => {
        const spacing = 75;
        const borderX = (window.innerWidth - (Math.floor(window.innerWidth / spacing) * spacing)) / 2;
        const borderY = (window.innerHeight - (Math.floor(window.innerHeight / spacing) * spacing)) / 2;

        let nodes: Node[] = [];
        const existingDots = document.querySelectorAll(".dot");
        if (existingDots) {
            for (const dot of existingDots) {
                dot.remove();
            }
        }
        const background = document.getElementById("background");
        for (let i = borderX; i <= window.innerWidth; i += spacing) {
            for (let j = borderY; j <= window.innerHeight; j += spacing) {

                let div = document.createElement("div");
                div.style.width = "3px";
                div.style.height = "3px";
                div.style.borderRadius = "30px";
                div.className = "dot";
                div.style.position = "absolute";
                div.style.left = `${i}px`;
                div.style.top = `${j}px`;
                nodes.push(div);
            }
        }
        background?.append(...nodes);
        radialGradient = document.querySelectorAll('.dot') as NodeListOf<HTMLElement>;
    }

    const gradientFollow = () => {
        const vw = window.innerWidth;
        const maxDistance = (10 / 100) * vw;
        if (radialGradient) {
            for (const item of radialGradient) {
                const x = item.offsetLeft + item.offsetWidth / 2;
                const y = item.offsetTop + item.offsetHeight / 2;
                const distance = Math.sqrt((mouseX - x) ** 2 + (mouseY - y) ** 2);
                if (distance < maxDistance + 100 || item.style.transform !== "scale(0)") {
                    const factor = 1 - Math.min(distance / maxDistance, 1);

                    const scale = 1 + factor;
                    const opacity = 0.5 + factor * 1;

                    item.style.transform = `scale(${scale})`;
                    item.style.opacity = opacity.toString();
                }
            }
        }
        requestAnimationFrame(gradientFollow);
    };


    const setupGradientFollow = () => {
        document.addEventListener('mousemove', (event) => {
            mouseX = event.pageX - window.scrollX;
            mouseY = event.pageY - window.scrollY;
        });
    }

    return (
        <div>
            <div id="background" className="fixed top-0 left-0">
                {/* Procedurally generated background dots */}
            </div>
            {children}
        </div>
    )
}

export default Background;