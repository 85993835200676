'use client'
import React, {useRef} from "react";
import Scrollbar from "src/components/scrollIndicator";
import Background from "src/components/bg-spotlight";
import ScrollPrompt from "../components/scrollPrompt";

export default function Home() {
    const mainRef = useRef(null);

    return (
        <>
            <Background>
                <main ref={mainRef}
                      className="bg-zinc-950 snap-y snap-mandatory overflow-y-auto overflow-x-hidden h-screen text-slate-100">
                    <div
                        className="snap-center h-screen w-screen overflow-hidden select-none flex justify-center items-center flex-col">
                        <div className="px-3 flex justify-center items-center flex-col">
                            <div className="align-center mb-6 flex font-medium">
                                <h1>Hey,&nbsp;</h1>
                                <h1>{"I'm Caleb"}</h1>
                            </div>
                            <div className="font-light w-min min-w-full ps-[0.3em]">
                                <h3 className="w-fit">I'm a full stack engineer with a passion for web
                                    development. I strive to build solutions that are not only useful and beautiful, but
                                    work.&nbsp;<i>Most of the time.</i></h3>
                            </div>
                        </div>
                    </div>
                    <div
                        className="snap-center h-screen w-screen overflow-hidden select-none flex justify-center items-center flex-col relative">
                        <h1 className="text-4xl font-light">More coming soon</h1>
                    </div>
                </main>
            </Background>
            <div className="hidden md:block">
                <Scrollbar scrollableEl={mainRef} pageCount={2}/>
            </div>
            <ScrollPrompt scrollElement={mainRef}/>
        </>
    )

}
