import React, {MutableRefObject, useEffect} from "react";
import 'src/styles/indicator.css';

type scrollProps = {
    scrollableEl: MutableRefObject<any>;

    pageCount: number;
}

export default function Scrollbar(props: scrollProps) {

    useEffect(() => {
        if (props.scrollableEl.current) {
            generateIndicators()
            props.scrollableEl.current.addEventListener('scroll', checkScrollPosition);
        }
    }, [props.scrollableEl]);

    function generateIndicators() {
        let firstIndicator = true;
        const indicatorParent = document.getElementById("indicatorParent");
        if (indicatorParent) {
            indicatorParent.innerHTML = "";
            for (let i = 0; i < props.pageCount; i++) {
                const indicator = document.createElement("div");
                if (firstIndicator) {
                    indicator.id = "selected";
                    firstIndicator = false;
                }
                indicator.className = "w-3 h-3 my-2 bg-neutral-200 rounded-full indicator";
                indicatorParent.appendChild(indicator);
            }
        }
    }

    function checkScrollPosition() {
        if (props.scrollableEl.current) {
            const pageLength = window.innerHeight;
            const scrollPosition = props.scrollableEl.current.scrollTop;
            const pageLengthsScrolled = Math.max(Math.floor(scrollPosition / pageLength), 0);

            const selected = document.getElementById("selected");
            if (selected) {
                selected.id = '';
            }
            const indicators = document.querySelectorAll(".indicator");
            if (indicators.length >= pageLengthsScrolled) {
                indicators[pageLengthsScrolled].id = "selected";
            }
        }

    }

    return (
        <div id="indicatorParent"
             className="fixed inset-y-1/2 right-6 -translate-y-1/2 h-fit flex justify-center items-center flex-col">
            {/* Scroll indicator dots procedurally generated */}
        </div>
    )
}